/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 11.09.2018
 *
 * Мобильные слайдеры на странице товара.
 *
 * Слайдер похожих товаров.
 * Слайдер популярных товаров.
 * Слайдер акций магазина.
 * Слайдер фотографий товара.
 *
 * -------------------------------------------
 *
 * Мобильные слайдеры главной страницы.
 *
 * Блок товаров в шапке.
 * Блок акций.
 * Блок популярных товаров.
 * Блок отзывов.
 */
$(function () {

  const $window = $(window);

  let ww;

  $('.js-swipe')
    .each(function (i, slider) {
      let swiper;
      let $items;
      let timeout;

      const $slider = $(slider);

      function rebuildSlider() {
        let html       = '';
        let initSwiper = false;

        if (swiper && swiper.destroy) {
          // удаляем старый слайдер.
          swiper.destroy(true, true);

          $slider
            .html('')
            .removeClass('swiper-container');
        }

        if (ww > 768) {
          // при большом разрешении элементы выводятся, как есть.

          $items.each((k, el) => {
            html += el.outerHTML;
          });
        } else {
          // при маленьком разрешении элементы выводятся в слайдере.

          $slider.addClass('swiper-container');

          html += '<div class="swiper-wrapper">';

          $items.each((k, el) => {
            html += $(el.outerHTML).addClass('swiper-slide')[0].outerHTML;
          });

          html += '</div>';
          html += '<div class="swiper-pagination"></div>';

          initSwiper = true;
        }

        $slider.html(html);

        if (initSwiper) {
          swiper = new Swiper($slider, {
            autoHeight:    false,
            slidesPerView: 'auto',
            spaceBetween:  10,
            pagination:    {
              el:        '.swiper-pagination',
              clickable: true,
            },
          });
        }
      }

      if ($slider.length) {
        ww = $window.width();

        $items = $slider.find('> *').clone();

        rebuildSlider();

        $window
          .on('resize', () => {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
              // при изменении размера окна, пересобираем слайдер заново.
              ww = $window.width();

              rebuildSlider();
            }, 100);
          });
      }
    });
});
