/**
 * Created by Ilya Kvant [fatalerror2069@gmail.com] on 10.09.2018
 *
 * Скрипт слайдера блока отзывов на главной.
 */
$(function () {

  const $indexBlog = $('.js-index-blog');
  const $window    = $(window);

  let swiper;
  let $items;

  let ww;

  /**
   * Сборка списка записей блога.
   */
  function rebuildSlider() {
    let html = '';

    if (swiper && swiper.destroy) {
      // удаляем старый слайдер.
      swiper.destroy();

      $('.swiper-wrapper', $indexBlog).html('');
    }

    if (ww > 768) {
      // при большом разрешении элементы выводятся парами.

      for (let i = 0, _len = $items.length; i < _len; i += 2) {
        let nextItem = $items.eq(i + 1)[0];

        html += `<div class="swiper-slide">${$items.eq(i)[0].outerHTML}${nextItem ? nextItem.outerHTML : ''}</div>`;
      }
    } else {
      // при маленьком разрешении элементы выводятся по-одному.

      $items.each((k, el) => {
        html += `<div class="swiper-slide">${el.outerHTML}</div>`;
      });
    }

    $('.swiper-wrapper', $indexBlog).html(html);

    swiper = new Swiper($indexBlog, {
      autoHeight:    false,
      slidesPerView: 1,
      spaceBetween:  null,
      pagination:    {
        el:        '.swiper-pagination',
        clickable: true,
      },
      navigation:    {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });
  }

  if ($indexBlog.length) {
    ww = $window.width();

    $items = $('.swiper-wrapper', $indexBlog).find('.blog-list__item').clone();

    rebuildSlider();

    let timeout;

    $window
      .on('resize', () => {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
          // при изменении размера окна, пересобираем слайдер заново.
          ww = $window.width();

          rebuildSlider();
        }, 100);
      });
  }
});
